import { createElement, FC, ReactElement } from 'react';
import { Link } from 'packages/links';
import {
  createOpenWithInAppBrowserAnchor,
  InAppBrowserOptions
} from 'apps/acp/packages/cordova-in-app-browser';
import {
  AuthorizeLoginHashData,
  createLoginAuthorizeUrl
} from 'apps/acp/micro-frontends/login/authorize-utils';
import { AcquisitionOptions } from './types/acquisition-options';
import { useDecorateAcquisitionUrl } from './decorate-acquisition-url';

interface AcquireNewCustomerInjectableDependencies {
  inAppBrowserToolbarBackgroundColor?: string;
  inAppBrowserDisableToolbarText?: boolean;
  inAppBrowserDisableToolbarTranslucent?: boolean;
  acquisitionUrl?: string;
  loginMfeRoot: Link;
}

export interface AcquireNewCustomerProps {
  children: (acquisitionUrlAnchor: {
    href: string;
    onClick: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }) => ReactElement;
  pageName: string;
  acquisitionOptions?: AcquisitionOptions;
}

type InternalAcquireNewCustomerProps = AcquireNewCustomerInjectableDependencies &
  AcquireNewCustomerProps;

// This component is not finished yet for now this only take cares
// the web application scenario without appsflyer integration.
// In further stories we will be handling AppsFlyer integration and mobile apps scenario.
const InternalAcquireNewCustomer: FC<InternalAcquireNewCustomerProps> = ({
  children,
  pageName,
  inAppBrowserToolbarBackgroundColor,
  inAppBrowserDisableToolbarText,
  inAppBrowserDisableToolbarTranslucent,
  loginMfeRoot,
  acquisitionUrl,
  acquisitionOptions
}) => {
  const decoratedAcquisitionUrl: string = useDecorateAcquisitionUrl(
    acquisitionUrl || '',
    pageName,
    acquisitionOptions ? acquisitionOptions : {}
  );

  const options: InAppBrowserOptions = {};

  if (inAppBrowserDisableToolbarText) {
    options.headertext = '';
  }

  if (inAppBrowserToolbarBackgroundColor) {
    options.toolbarcolor = inAppBrowserToolbarBackgroundColor;
  }
  if (inAppBrowserDisableToolbarTranslucent) {
    options.toolbartranslucent = 'no';
  }

  const acquisitionUrlAnchor = createOpenWithInAppBrowserAnchor(
    decoratedAcquisitionUrl,
    options,
    {
      acpLoginHandler: (closeInAppBrowser) => (
        accessTokenOrPayload: string | AuthorizeLoginHashData
      ) => {
        // Accepts payload object (or string for backwards compatibility w/ angularjs-acquisition)
        let payload: AuthorizeLoginHashData;
        if (typeof accessTokenOrPayload === 'string') {
          payload = {
            access_token: accessTokenOrPayload
          };
        } else if (
          typeof accessTokenOrPayload === 'object' &&
          accessTokenOrPayload.access_token
        ) {
          payload = {
            access_token: accessTokenOrPayload.access_token,
            redirect_to: accessTokenOrPayload.redirect_to
          };
        } else {
          return;
        }

        // Gateway drops the hash when redirecting, so we can't use a gateway-rpc link here. Instead, use the mfe's route declaration link to construct the full route with the hash segment
        const route = createLoginAuthorizeUrl(loginMfeRoot, payload);
        // Open the page fresh (ie avoid SPA transition), to ensure gateway doesnt try to swap the current route for the internal gateway route
        window.location.assign(route);
        closeInAppBrowser();
      }
    }
  );

  return children(acquisitionUrlAnchor);
};

export type AcquireNewCustomerType = FC<AcquireNewCustomerProps>;

export const createAcquireNewCustomer = (
  dependencies: AcquireNewCustomerInjectableDependencies
): AcquireNewCustomerType => (props) => (
  <InternalAcquireNewCustomer {...dependencies} {...props} />
);

export * from './types/acquisition-options';
export * from './context';
