import { isAndroid, isIOS } from 'packages/platform-detector';

export const isMobileBrowser = () => {
  const device = window.navigator.userAgent;
  return (
    device.match(/Android/i) ||
    device.match(/BlackBerry/i) ||
    device.match(/BB10/i) ||
    device.match(/Opera Mini/i) ||
    device.match(/IEMobile/i) ||
    device.match(/iPhone|iPad|iPod/i)
  );
};

export const isMobile = isMobileBrowser() || isAndroid() || isIOS();

export const lowerCaseKeys = (
  params: Record<string, string>
): Record<string, string> => {
  const lowerCased: Record<string, string> = {};
  Object.keys(params).forEach((key: string) => {
    lowerCased[key.toLowerCase()] = params[key];
  });
  return lowerCased;
};
