import {
  AcquisitionOptions,
  AcquisitionKeys
} from './types/acquisition-options';
import {
  updateQueryStringParameter,
  getQueryStringParameters
} from './query-string-parser';
import { lowerCaseKeys, isMobile } from './utility';

const getSiteId = (
  acquisitionUrl: string,
  options: AcquisitionOptions,
  pageName: string
): string => {
  const queryStringParams: Record<string, string> = lowerCaseKeys(
    getQueryStringParameters(acquisitionUrl)
  );
  // site_id is never provided without aid, so no need to update site_id without it
  let siteId = '';
  if (queryStringParams.aid) {
    if (options && options.site_id) {
      return options.site_id;
    } else {
      siteId = queryStringParams.site_id;
      if (siteId) {
        // Want to avoid e.g. login_login
        if (pageName !== siteId) {
          siteId = `${pageName}_${siteId}`;
        }
      } else {
        siteId = pageName;
      }

      if (siteId.indexOf('mobile') === -1 && isMobile) {
        siteId += '_mobile';
      }
    }
  }
  return siteId;
};

const hasOwnProperty = Object.prototype.hasOwnProperty;

export const useDecorateAcquisitionUrl = (
  acquisitionUrl: string,
  pageName: string,
  options: AcquisitionOptions
): string => {
  if (options && options.use_absolute_parameters) {
    delete options.use_absolute_parameters;
    for (const key in options) {
      if (hasOwnProperty.call(options, key)) {
        acquisitionUrl = updateQueryStringParameter(
          acquisitionUrl,
          key,
          options[key as AcquisitionKeys] || ''
        );
      }
    }
    return acquisitionUrl;
  }

  const siteId = getSiteId(acquisitionUrl, options, pageName);
  if (siteId) {
    return updateQueryStringParameter(acquisitionUrl, 'site_id', siteId);
  }
  return acquisitionUrl;
};
